<template>
  <v-container fluid>
    <p class="text-uppercase grey--text">
      <span class="font-weight-light">NEW </span> FACULTY
    </p>

    <v-overlay :absolute="absolute" :value="overlay" z-index="90">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/faculty_management/faculty_list"
          color="primary"
        >
          FACULTY LIST
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-row justify="center" align="center">
                <v-col cols="12" sm="3" md="3" lg="3">
                  <v-text-field
                    outlined
                    label="Faculty Code"
                    hide-details="auto"
                    v-model="faculty_code"
                    :rules="[(v) => !!v || 'Faculty Code is required']"
                    :error-messages="error_msgs"
                    min="0"
                    :loading="loadFaculty"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="6">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    clearable
                    label="New Faculty Name"
                    v-model="faculty_name"
                    :rules="[(v) => !!v || 'Faculty Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col class="text-center" cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    v-if="
                      getters_abilities.includes('create_department_access')
                    "
                    @click="saveFacultyBtn"
                    :disabled="!valid"
                    large
                    class="mx-2"
                    color="primary"
                  >
                    save faculty
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>

    <Success v-if="successFeedback">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Success>
  </v-container>
</template>
<script>
  import { getCurrentInstance, reactive, ref, toRefs } from "vue";
  import {
    useActions /* useGetters */,
    useGetters,
  } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";
  import Success from "@/components/Error/Success";

  export default {
    components: { Error, Success },
    setup() {
      const vm = getCurrentInstance();
      let { saveFaculty, signOut } = useActions(["saveFaculty", "signOut"]);
      const { getters_abilities } = useGetters(["getters_abilities"]);
      const form = ref(null);

      let facultyForm = reactive({
        valid: true,
        error_msgs: [],
        faculty_code: null,
        faculty_name: null,
        max25chars: (v) => v.length <= 25 || "Input too long!",
        loadFaculty: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        successFeedback: false,
        absolute: true,
        overlay: false,
      });

      const {
        faculty_name,
        faculty_code,
        successFeedback,
        deleteResponse,
        msgHeader,
        msgBody,
        msgIcon,
        overlay,
      } = toRefs(facultyForm);

      const saveFacultyBtn = () => {
        deleteResponse.value = false;
        successFeedback.value = false;

        if (form.value.validate()) {
          overlay.value = true;

          saveFaculty({
            faculty_code: faculty_code.value,
            faculty_name: faculty_name.value,
          })
            .then(() => {
              overlay.value = false;
              successFeedback.value = true;
              msgHeader.value = "Success";
              msgBody.value = "Saved";
              msgIcon.value = "mdi-check-circle";
              form.value.reset();
            })
            .catch((e) => {
              overlay.value = false;
              deleteResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              form.value.reset();
              if (e.response.status === 422) {
                msgBody.value =
                  "Either the Department ID or the Department name exists.";
              } else if (e.response.status === 403) {
                msgBody.value = "This action is unauthorized";
              } else if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              }
            });
        }
      };

      return {
        ...toRefs(facultyForm),
        saveFacultyBtn,
        form,
        getters_abilities,
      };
    },
  };
</script>
